<template>
  <section id="dashboard-analytics">
    <version-alert />
    <!-- upcoming features sidebar -->
    <upcoming-features-user-sidebar />
    <b-row class="match-height">
      <b-col lg="12" md="12">
        <analytics-congratulation :data="data.congratulations" />
      </b-col>
      <!-- <b-col lg="12" md="12">
        <home-map></home-map>
      </b-col> -->
      <!-- <b-col lg="12" md="12">
        <home-map-2></home-map-2>
      </b-col> -->
      <!-- <b-col lg="12" md="12">
        <sample-map></sample-map>
      </b-col> -->
    </b-row>
  </section>
</template>

<script>
// import HomeMap from "../../map/components/HomeMap.vue";
import { BRow, BCol } from "bootstrap-vue";

import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import InvoiceList from "@/views/invoice/invoice-list/InvoiceList.vue";
import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
import AnalyticsTimeline from "./AnalyticsTimeline.vue";
import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
import AnalyticsAppDesign from "./AnalyticsAppDesign.vue";
import VersionAlert from "../../listings/components/VersionAlert.vue";
import UpcomingFeaturesUserSidebar from "../../features/components/UpcomingFeaturesUserSidebar.vue";

export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
    // HomeMap,
    VersionAlert,
    UpcomingFeaturesUserSidebar,
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
  methods: {
    kFormatter,
  },
};
</script>
<style>
.card-body {
  min-height: 1px !important;
}
</style>
