<template>
  <div>
    <b-card v-if="user" text-variant="center" class="card card-congratulations">
      <!-- images -->
      <b-img :src="require('@/assets/images/elements/decore-left.png')" class="congratulations-img-left" />
      <b-img :src="require('@/assets/images/elements/decore-right.png')" class="congratulations-img-right" />
      <!--/ images -->

      <b-avatar variant="primary" size="70" class="shadow mb-2">
        <feather-icon size="28" icon="AwardIcon" />
      </b-avatar>
      <h1 class="mb-1 mt-50 text-white">Welcome {{ user.name }}!!</h1>

      <!-- <b-button id="show-btn" @click="showModal">Open Modal</b-button>
    <b-button id="toggle-btn" @click="toggleModal">Toggle Modal</b-button> -->
    </b-card>

    <b-card-group deck v-if="user.role == 'user'">
      <b-card class="col-sm-4">
        <div class="title-listing">
          <feather-icon size="20" icon="DatabaseIcon" />
          <span>Listing</span>
        </div>
        <!-- <h3>
        
      </h3> -->
        <b-card-text>Explore the database of listings by clicking the link below</b-card-text>
        <button class="btn btn-primary title-listing-btn" @click="$router.push('BCCListings')" style="top: 33% !important">Listing</button>
      </b-card>

      <b-card class="col-sm-4">
        <div class="title-listing">
          <feather-icon size="20" icon="HeartIcon" />
          <span>Favourites</span>
        </div>
        <!-- <h3>
        
      </h3> -->
        <b-card-text>Explore your saved listing by clicking on the link below</b-card-text>
        <button class="btn btn-primary title-listing-btn" @click="$router.push('SavedListings')">Favourites</button>
      </b-card>

      <b-card class="col-sm-4">
        <div class="title-listing">
          <feather-icon size="20" icon="FlagIcon" />
          <span>REMAP.ai Update 2.6</span>
        </div>
        <!-- <h3>
        
      </h3> -->
        <b-card-text>
          <ul>
            <li>Map View - New map view in listing pages</li>
            <li>Updated Filters - Improved filters & exclusion</li>
            <li>Property Lookup - Review an individual property</li>
            <li>Suburb Search - Search a suburb or postcode for specific criteria</li>
            <li>Bug Fixes - Major & minor bug fixes in search & views</li>
          </ul>
        </b-card-text>
      </b-card>
    </b-card-group>

    <b-modal ref="my-modal" id="modal-prevent-closing" hide-footer centered title="Remap.ai">
      <div class="modal-body">
        <p>
          The sales listing information is obtained from multiple sources, and due to origination data structure/update irregularity REMAP.ai is sometimes
          unable to automatically verify the accuracy of the 'Sale' status.
        </p>
      </div>
      <div class="modal-footer">
        <div class="form-check float-left">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" @click="agree($event)" />
          <label class="form-check-label" for="flexCheckDefault" style="font-size: 13px !important">Please click on checkbox to agree</label>
        </div>
        <!-- <div class="">
          <input type="checkbox" name="agreeCheck" id="agreeCheck"> 
        </div> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText, BModal, BButton, BLink, BRow, BCardGroup } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BButton,
    BModal,
    BLink,
    BRow,
    BCardGroup,
    ToastificationContent,
  },
  data() {
    return {
      user: {},
    };
  },
  mounted() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.user = userData;
    gtag("event", "filters", {
      event_category: "Filters",
      event_label: "filters",
      value: JSON.stringify(userData),
    });
    if (this.user.role != "admin" && !this.user.isAgree) {
      this.$refs["my-modal"].show();
    }
  },
  methods: {
    async agree(event) {
      if (event.target.checked) {
        let id = this.user.id;
        let agree = await this.$axios.get(`${this.$API_URL_USER}/agreeUser/${id}`);

        if (agree.data.success) {
          this.user.isAgree = true;
          localStorage.setItem("userData", JSON.stringify(this.user));
          Vue.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Thank you`,
            },
          });
          this.$refs["my-modal"].hide();
        } else {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Something went wrong`,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      }
    },
  },
  // props: {
  //   data: {
  //     type: Object,
  //     default: () => {
  //       let userData = JSON.parse(localStorage.getItem("userData"));
  //       // console.log(data);
  //     },
  //   },
  // },
};
</script>

<style>
[dir="ltr"] .modal .modal-header .close {
  transform: scale(1) !important;
  background: #f8f8f8 !important;
  box-shadow: 0 0 0 0 !important;
}

.modal-footer {
  position: relative !important;
  display: block !important;
}

.title-listing {
  margin-bottom: 10px;
}

.title-listing span {
  font-size: 20px;
  top: 3px;
  position: relative;
  font-weight: 500;
  margin-left: 3px;
}

.title-listing-btn {
  float: right;
  top: 33%;
  position: relative;
}
</style>
